import { forwardRef } from "react";
import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor";
const ImageEditor = forwardRef((props, ref) => {
  const { showApproveButton, onSaveImage, media } = props;
  const imageEditorCreated = () => {
    ref.current.open(media.mediaFileUrl);
  };
  return (
    <div className="e-img-editor-sample">
      <ImageEditorComponent
        id="image-editor"
        ref={ref}
        created={imageEditorCreated}
      />

      {showApproveButton && (
        <div
          className="controls"
          style={{
            position: "absolute",
            top: 55,
            right: 28,
          }}
        >
          <button
            title="Save changes"
            className="trim-control"
            onClick={onSaveImage}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
});

export default ImageEditor;
