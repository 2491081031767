import React, { useState, useEffect } from "react";
import "./TabPanel.css";
import ToolBar from "../../../screens/Social/SocialOperator/ToolBar";
import { Delete } from "@mui/icons-material";

function TabPanel({ tabs, onTabChange, onPurgeAllClick, toolbarprops }) {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    onTabChange(activeTab);
  }, [activeTab]);

  return (
    <div className="panel">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`tab-button ${activeTab === index ? "active" : ""}`}
          >
            {tab.icon}
            {tab.label}
          </button>
        ))}
        <div className="tabs-action-buttons">
          <button className="tab-button purge-button" onClick={onPurgeAllClick}>
            <Delete className="w-5 h-5" />
            Purge All Media
          </button>
        </div>
      </div>

      <div className="tp-content">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-content ${activeTab === index ? "active" : ""}`}
          >
            {toolbarprops && (
              <ToolBar {...toolbarprops} activeTab={activeTab} />
            )}
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TabPanel;
