import React, { useEffect, useState } from "react";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { checkMediaOrientation, getOrientation } from "./CheckOrientation";
import { Settings } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "./FixedMasonry.css";
import AutoSizer from "react-virtualized-auto-sizer";
import Loader from "../../../components/Loader/Loader";
import SocialOutputSettings from "../Settings";
import {
  DEFAULT_SETTINGS_FIXED_MASONRY,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../../utils/defaultSettings";
import {
  getItemFromSessionStorage,
  isLandscapeCardOfMasonry,
  mergeAndDeduplicate,
} from "../../../utils/utility";
import { useRef } from "react";
import GeneratedMediaFlippingCard from "./GeneratedMediaFlippingCard";

const MAX_OBJECTS_THRESHOLD = 80;
const REMOVE_COUNT = 10;
const POLLING_INTERVAL = 6000;
const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.fixedMasonry;
var totalPages = 0;

const AutoResizedSocialDynamicMasonry = (props) => {
  const getInitialSettings = () => {
    if (props.savedSettings) {
      return props.savedSettings;
    } else {
      return getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        : DEFAULT_SETTINGS_FIXED_MASONRY;
    }
  };
  const [settings, setSettings] = useState(getInitialSettings);

  const [landscapeMedia, setLandscapeMedia] = useState([]);
  const [portraitMedia, setPortraitMedia] = useState([]);

  const [page, setPage] = useState(null);
  //const perPage = 20;
  const [mediaIds, setMediaIds] = useState(new Set());
  const [dialogOpen, setDialogOpen] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [forceFetch, setForceFetch] = useState(false);
  const [NumberOfCards, setNumberOfCard] = useState(settings.selectedLayout);
  let isRelayout = useRef(false);
  let timeoutId1 = null;
  let timeoutId2 = null;
  useEffect(() => {
    props.onSettingsChange(settings);
  }, [settings]);
  useEffect(() => {
    setForceFetch(true);
    setNumberOfCard(settings.selectedLayout);
  }, [settings.selectedLayout]);
  useEffect(() => {
    if (props.savedSettings) {
      isRelayout.current = true;
      setSettings(props.savedSettings);
    }
    return () => {
      isRelayout.current = false;
    };
  }, [props.savedSettings]);
  useEffect(() => {
    isRelayout.current = true;
    setPage((pre) => {
      if (pre === 0 && forceFetch) {
        setForceFetch(false);
        fetchContent(page);
      }
      return 0;
    });
    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [NumberOfCards]);
  useEffect(() => {
    isRelayout.current = true;
    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, []);
  useEffect(() => {
    fetchContent(page);

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [page]);

  const [firstLoadArray, setFirstLoadArray] = useState([]);

  const eventId = window.location.hash.replace("#", "") || props.eventId;

  const checkImageOrientationAsync = (imageUrl, mimeType, sharedMediaId) => {
    return new Promise((resolve, reject) => {
      checkMediaOrientation(
        imageUrl,
        mimeType,
        (error, result, errorEvent) => {
          if (error) {
            console.error(error, errorEvent);
            reject(new Error(error));
          } else {
            resolve(result);
          }
        },
        sharedMediaId
      );
    });
  };

  const fetchContent = async (
    pageNumber,
    dontShowLoader = false,
    previousMediaCount = 0
  ) => {
    if (pageNumber === null) {
      return;
    }
    try {
      if (isRelayout.current === true && !dontShowLoader) {
        setLoading(true);
      }
      const perPage = NumberOfCards ? NumberOfCards * 3 : 20;
      const rawResponse = await uvenuFetcher({
        url: URLS.GET_SOCIAL_SCROLL(
          eventId,
          pageNumber,
          perPage,
          undefined,
          undefined,
          "desc",
          undefined,
          undefined,
          true
        ),
        method: "GET",
      });
      setLoading(false);
      const response = rawResponse.json;
      const mediaFiles = response?.mediaFiles || [];

      if (mediaFiles.length > 0) {
        const totalMediaCount = response.totalCount;
        totalPages = Math.ceil(totalMediaCount / perPage);
        const classifiedMedia = { landscape: [], portrait: [] };
        const newMediaIds = new Set(mediaIds);
        for (const item of mediaFiles) {
          try {
            if (item.metadata && item.metadata.width && item.metadata.height) {
              if (!item.orientation) {
                item.metadata["orientation"] = getOrientation(
                  item.metadata.width,
                  item.metadata.height
                ).orientation;
              }
            } else {
              const result = await checkImageOrientationAsync(
                item.mediaFileUrl,
                item.mimeType,
                item._id
              );
              item.metadata = result;
            }

            const { orientation, width, height } = item.metadata;
            item.orientation = orientation;
            item.width = width;
            item.height = height;
            if (classifiedMedia[orientation]) {
              classifiedMedia[orientation].push(item);
            } else {
              classifiedMedia.portrait.push(item);
            }

            newMediaIds.add(item._id);
          } catch (error) {
            console.error(
              "Error determining image orientation:",
              error.message
            );
          }
        }
        setMediaIds(newMediaIds);
        if (isRelayout.current === true) {
          if (previousMediaCount !== mediaFiles.length) {
            setFirstLoadArray(
              getFirstLoadOrientationPatternedArray(classifiedMedia)
            );
          }

          if (totalPages > 1) {
            isRelayout.current = false;
            clearTimeout(timeoutId1);
          } else {
            clearTimeout(timeoutId1);
            timeoutId1 = setTimeout(() => {
              fetchContent(0, true, mediaFiles.length);
            }, POLLING_INTERVAL);
          }
        } else {
          setLandscapeMedia((prevMedia) =>
            mergeAndDeduplicate([...prevMedia], [...classifiedMedia.landscape])
          );

          setPortraitMedia((prevMedia) =>
            mergeAndDeduplicate([...prevMedia], [...classifiedMedia.portrait])
          );
        }
      } else {
        setFirstLoadArray(getPlaceholderCardsArray(NumberOfCards));
        clearTimeout(timeoutId2);
        timeoutId2 = setTimeout(() => {
          fetchContent(0, true);
        }, POLLING_INTERVAL);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  useEffect(() => {
    if (landscapeMedia.length + portraitMedia.length < NumberOfCards * 3) {
      if (totalPages) {
        setPage((prePage) => {
          if (prePage < totalPages - 1) {
            return prePage + 1;
          } else {
            return 0;
          }
        });
      }
    } else if (
      landscapeMedia.length + portraitMedia.length >=
      MAX_OBJECTS_THRESHOLD
    ) {
      if (landscapeMedia.length > REMOVE_COUNT) {
        setLandscapeMedia((pre) => pre.slice(REMOVE_COUNT));
      } else if (portraitMedia.length > REMOVE_COUNT) {
        setPortraitMedia((pre) => pre.slice(REMOVE_COUNT));
      }
    }
  }, [landscapeMedia, portraitMedia, NumberOfCards]);

  // const onRequestNewMeida = (cb, orientation, m1, m2) => {
  //   // if (
  //   //   m1._id === m2?.hasGeneratedMediaId ||
  //   //   m1._id === m2?.generatedFromMediaId
  //   // ) {
  //   //   console.log(
  //   //     "it's a Pair",
  //   //     m1._id,
  //   //     m2?.hasGeneratedMediaId,
  //   //     m2?.generatedFromMediaId
  //   //   );
  //   //   if (orientation === "portrait" || orientation === "square") {
  //   //     if (portraitMedia.length) {
  //   //       const copy = [...portraitMedia];
  //   //       cb(copy.shift());
  //   //       setPortraitMedia(copy);
  //   //     } else {
  //   //       const copy = [...landscapeMedia];
  //   //       cb(copy.shift());
  //   //       setLandscapeMedia(copy);
  //   //     }
  //   //   } else if (orientation === "landscape") {
  //   //     if (landscapeMedia.length) {
  //   //       const copy = [...landscapeMedia];
  //   //       cb(copy.shift());
  //   //       setLandscapeMedia(copy);
  //   //     } else {
  //   //       const copy = [...portraitMedia];
  //   //       cb(copy.shift());
  //   //       setPortraitMedia(copy);
  //   //     }
  //   //   }
  //   // } else {
  //   //   console.log(
  //   //     "it's not a Pair",
  //   //     m1._id,
  //   //     m2?.hasGeneratedMediaId,
  //   //     m2?.generatedFromMediaId
  //   //   );
  //   //   const pairItem = getPairItem(m1._id);
  //   //   cb(pairItem);
  //   // }
  // };
  const onRequestNewMeida = (
    cb,
    orientation,
    getOriginal,
    requestedMediaId
  ) => {
    const mediaArray =
      orientation === "portrait" || orientation === "square"
        ? portraitMedia
        : landscapeMedia;
    const setMediaArray =
      orientation === "portrait" || orientation === "square"
        ? setPortraitMedia
        : setLandscapeMedia;
    if (mediaArray.length) {
      if (getOriginal) {
        let indexToRemoveFrom = -1;
        const newOriginalMedia = mediaArray.find((m, i) => {
          if (m?.hasGeneratedMediaId) {
            indexToRemoveFrom = i;
            return true;
          }
          return false;
        });
        if (newOriginalMedia && indexToRemoveFrom !== -1) {
          cb(newOriginalMedia);
          const copy = [...mediaArray];
          copy.splice(indexToRemoveFrom, 1);
          setMediaArray(copy);
        }
      } else if (requestedMediaId) {
        let indexToRemoveFrom = -1;
        const newGeneratedMedia = mediaArray.find((m, i) => {
          if (m._id === requestedMediaId) {
            indexToRemoveFrom = i;
            return true;
          }
          return false;
        });
        if (newGeneratedMedia && indexToRemoveFrom !== -1) {
          cb(newGeneratedMedia);
          const copy = [...mediaArray];
          copy.splice(indexToRemoveFrom, 1);
          setMediaArray(copy);
        }
      }
    }
  };

  const getPairItem = (id) => {
    let index = -1;
    // let pairItem = { _id: `placeholder-${id}`, mimeType: "placeholder" }
    const pairItem = portraitMedia.find((m, i) => {
      const isMatch =
        m?.generatedFromMediaId === id || m?.hasGeneratedMediaId === id;
      if (isMatch) {
        index = i;
      }
      return isMatch;
    });
    if (pairItem && index !== -1) {
      portraitMedia.splice(index, 1);
      return pairItem;
    } else {
      let index_l = -1;
      const pairItem_l = landscapeMedia.find((m, i) => {
        const isMatch =
          m?.generatedFromMediaId === id || m?.hasGeneratedMediaId === id;
        if (isMatch) {
          index = i;
        }
        return isMatch;
      });
      if (pairItem_l && index_l !== -1) {
        landscapeMedia.splice(index_l, 1);
        return pairItem_l;
      }
    }
    return { _id: `placeholder-${id}`, mimeType: "placeholder" };
  };

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog open={dialogOpen || false} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="dialog-content">
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={settings}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const [currentFlipIndex, setCurrentFlipIndex] = useState(-1);
  const [flipId, setFlipId] = useState(
    firstLoadArray.length
      ? firstLoadArray[0][0]._id + firstLoadArray[0][1]._id
      : ""
  );

  const getPlaceholderCardsArray = (cardsCount) => {
    const placeholders = [];
    for (let i = 0; i < cardsCount; i++) {
      placeholders.push([
        { _id: `placeholder-${i}`, mimeType: "placeholder" },
        { _id: `placeholder-${i}1`, mimeType: "placeholder" },
      ]);
    }
    return placeholders;
  };

  const getFirstLoadOrientationPatternedArray = ({
    landscape = [],
    portrait = [],
  }) => {
    const arr = [];
    if (portrait.length + landscape.length > 0) {
      for (let i = 0; i < NumberOfCards; i++) {
        let indexToRemoveFrom = -1;
        const card = [
          { _id: `placeholder-${0}-${i}`, mimeType: "placeholder" },
          { _id: `placeholder-2-${1}-${i}`, mimeType: "placeholder" },
        ];
        let mediaFiles = portrait;
        if (portrait.length + landscape.length > 0) {
          if (isLandscapeCardOfMasonry(i, settings.selectedLayout)) {
            if (landscape.length) {
              mediaFiles = landscape;
            } else if (portrait.length) {
              mediaFiles = portrait;
            }
          }
          let originalMedia = mediaFiles.find((m, i) => {
            if (m?.hasGeneratedMediaId) {
              indexToRemoveFrom = i;
              return true;
            }
            return false;
          });
          if (originalMedia && i !== -1) {
            card[0] = originalMedia;
            let indexToremoveGenerated = -1;
            mediaFiles.splice(indexToRemoveFrom, 1);
            let generatedMedia = mediaFiles.find((m, i) => {
              indexToremoveGenerated = i;
              if (m?.generatedFromMediaId === originalMedia._id) {
                indexToremoveGenerated = i;
                return true;
              }
              return false;
            });
            if (generatedMedia && indexToremoveGenerated !== -1) {
              card[1] = generatedMedia;
              mediaFiles.splice(indexToremoveGenerated, 1);
            }
          }

          arr.push(card);
        }
      }
    }
    if (landscape.length) {
      setLandscapeMedia((prevMedia) =>
        mergeAndDeduplicate([...prevMedia], [...landscape])
      );
    }
    if (portrait.length) {
      setPortraitMedia((prevMedia) =>
        mergeAndDeduplicate([...prevMedia], [...portrait])
      );
    }
    let emptyCards = [];
    if (arr.length < NumberOfCards) {
      const diff = NumberOfCards - arr.length;
      emptyCards = getPlaceholderCardsArray(diff);
    }
    return [...arr, ...emptyCards];
  };

  const getGeneratedItem = (mediaItem, portrait, landscape, side) => {
    let pairItem = null;
    if (mediaItem.hasGeneratedMediaId && side === 0) {
      // Checking only portrait because currently we are saving all generated media in portrait array
      let index = -1;
      pairItem = portrait.find((m, i) => {
        if (m._id === mediaItem.hasGeneratedMediaId) {
          index = i;
        }
        return m._id === mediaItem.hasGeneratedMediaId;
      });
      if (pairItem && index !== -1) {
        portrait.splice(index, 1);
      }
    } else if (mediaItem.generatedFromMediaId && side === 1) {
      let index = -1;
      pairItem = portrait.find((m, i) => {
        if (m._id === mediaItem.generatedFromMediaId) {
          index = i;
        }
        return m._id === mediaItem.generatedFromMediaId;
      });
      if (pairItem && index !== -1) {
        portrait.splice(index, 1);
      } else {
        pairItem = landscape.find((m, i) => {
          if (m._id === mediaItem.generatedFromMediaId) {
            index = i;
          }
          return m._id === mediaItem.generatedFromMediaId;
        });
        if (pairItem && index !== -1) {
          landscape.splice(index, 1);
        }
      }
    }
    return pairItem;
  };

  useEffect(() => {
    if (
      firstLoadArray.length &&
      firstLoadArray[currentFlipIndex] &&
      firstLoadArray[currentFlipIndex]
    ) {
      setFlipId(
        firstLoadArray[currentFlipIndex][0]._id +
          firstLoadArray[currentFlipIndex][1]._id
      );
    }
  }, [currentFlipIndex, firstLoadArray]);

  useEffect(() => {
    if (!firstLoadArray.length) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentFlipIndex((pre) => {
        if (pre < NumberOfCards - 1) {
          return pre + 1;
        } else {
          return 0;
        }
      });
    }, settings.flipDelay * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [firstLoadArray, settings.flipDelay, NumberOfCards]);

  const oneHalf = [];
  const twoHalf = [];
  for (let i = 0; i < firstLoadArray.length / 2; i++) {
    oneHalf.push(i % 2 === 0 ? "landscape" : "portrait");
  }
  for (let i = firstLoadArray.length / 2; i < firstLoadArray.length; i++) {
    twoHalf.push(i % 2 === 0 ? "portrait" : "landscape");
  }
  const getLayoutStyles = () => {
    if (settings.selectedLayout === 4) {
      return {
        aspectRatio: 4 / 3,
        width: props.height * (4 / 3) - settings.gap * 8 * (4 / 3),
        gridTemplateColumns: "1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedLayout === 7) {
      return {
        aspectRatio: 2.4,
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns: "2.127fr 1.04fr 1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedLayout === 10) {
      return {
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns:
          "1.263fr 1.263fr 2.127fr 1.04fr 1.263fr 1.263fr 2.127fr",
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    }
  };

  const getRootContainerStyles = () => {
    return {
      height: props.height,
      width: props.width,
      backgroundColor: settings.backgroundColor,
      backgroundImage: settings.backgroundImage
        ? `url(${settings.backgroundImage})`
        : "none",
    };
  };
  return (
    <div className="root-container" style={getRootContainerStyles()}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {renderSettings()}
          <div style={getLayoutStyles()} className="uv-fixed-masonry-grid">
            {firstLoadArray.length ? (
              firstLoadArray.map((s, i) => {
                return i < NumberOfCards && s && s[0] && s[1] ? (
                  <GeneratedMediaFlippingCard
                    keepFlipping={NumberOfCards === 1}
                    settings={settings}
                    m1={s[0]}
                    m2={s[1]}
                    key={s[0]._id + s[1]._id}
                    flipId={flipId}
                    onRequestNewMeida={onRequestNewMeida}
                    orientation={s[0].orientation}
                    className={`card-${settings.selectedLayout}-${i}`}
                  />
                ) : null;
              })
            ) : (
              <Loader />
            )}
          </div>
        </>
      )}
    </div>
  );
};
const GeneratedMediaFixedMasonry = (props) => {
  return (
    <>
      <div style={{ position: "fixed", inset: 0 }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <AutoResizedSocialDynamicMasonry
                width={width}
                height={height}
                {...props}
              ></AutoResizedSocialDynamicMasonry>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};
export default GeneratedMediaFixedMasonry;
