import { useEffect, useRef, useState } from "react";
import "./FlippingCard.css";
import { motion } from "framer-motion";
import MediaCard from "./MediaCard";

const SingleGeneratedMediaFlippingCard = ({
  m1,
  m2,
  onRequestNewMeida,
  settings,
}) => {
  const { borderWidth, borderColor, roundedCorners } = settings;
  const [media1Data, setMedia1Data] = useState(m1);
  const [media2Data, setMedia2Data] = useState(m2);
  const [isFlipped, setIsFlipped] = useState(null);

  const flipCardInnerRef = useRef(null);
  const frontRef = useRef(null);
  const backRef = useRef(null);

  const isImageLoaded = (side) => {
    const elem = side === 0 ? frontRef.current : backRef.current;
    if (elem) {
      return !!elem.naturalWidth;
    }
  };

  const [isImage1Loading, setIsImage1Loading] = useState(isImageLoaded(0));
  const [isImage2Loading, setIsImage2Loading] = useState(isImageLoaded(1));

  const getBorderRadius = () => {
    if (roundedCorners) {
      if (borderWidth > 5) {
        return `${borderWidth * 4}px`;
      } else {
        return "0.85em";
      }
    } else {
      return "0px";
    }
  };
  const [dynamicStyle, setDynamicStyle] = useState({
    border: `${borderWidth}px solid ${borderColor}`,
    borderRadius: getBorderRadius(),
  });

  useEffect(() => {
    setDynamicStyle({
      border: `${borderWidth}px solid ${borderColor}`,
      borderRadius: getBorderRadius(),
    });
  }, [borderWidth, borderColor, roundedCorners]);

  const getBackTurnedCardMediaSetter = () => {
    const elem = flipCardInnerRef.current;
    if (elem) {
      var style = window.getComputedStyle(elem);
      var matrix = new window.WebKitCSSMatrix(style.transform);
      if (Math.sign(matrix.a) === -1) {
        return setMedia1Data;
      } else {
        return setMedia2Data;
      }
    }
  };

  const requestNewMediaCb = (newMeida) => {
    if (newMeida && newMeida.mediaFileUrl) {
      const mediaCardDataSetter = getBackTurnedCardMediaSetter();
      mediaCardDataSetter(newMeida);
    }
  };

  const triggerFlip = () => {
    if (!isImage1Loading && !isImage2Loading) {
      setIsFlipped((pre) => {
        if (pre === true) {
          onRequestNewMeida(requestNewMediaCb, true, media1Data._id);
        } else if (pre === false) {
          const requestedMediaId =
            media1Data?.hasGeneratedMediaId || media2Data?.hasGeneratedMediaId;
          onRequestNewMeida(requestNewMediaCb, false, requestedMediaId);
        }
        return !pre;
      });
    }
  };

  useEffect(() => {
    let intervalId;
    clearInterval(intervalId);
    intervalId = setInterval(() => {
      triggerFlip();
    }, Number(settings.flipDelay) * 1000);

    return () => clearInterval(intervalId);
  }, [m1, m2, media1Data, media2Data, settings.flipDelay]);

  return (
    <div className="flip-card">
      <motion.div
        className="flip-card-inner"
        style={{ height: "auto", aspectRatio: 1 }}
        initial={false}
        animate={{ rotateY: isFlipped ? 180 : 360 }}
        transition={{ duration: 0.6, animationDirection: "normal" }}
        onAnimationComplete={() => {}}
        ref={flipCardInnerRef}
      >
        <div
          className="flip-card-front"
          style={dynamicStyle}
          id={media1Data._id}
        >
          <MediaCard
            key={media1Data._id}
            data={media1Data}
            onLoad={() => setIsImage1Loading(false)}
            ref={frontRef}
            enableBlur={false}
            settings={settings}
          />
        </div>
        <div
          className="flip-card-back"
          style={dynamicStyle}
          id={media2Data._id}
        >
          <MediaCard
            key={media2Data._id}
            data={media2Data}
            onLoad={() => setIsImage2Loading(false)}
            ref={backRef}
            enableBlur={false}
            settings={settings}
          />
        </div>
      </motion.div>
    </div>
  );
};
export default SingleGeneratedMediaFlippingCard;
