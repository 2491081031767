import { useEffect, useRef, useState } from "react";
import { connect } from "redux-zero/react";
import {
  Card,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  downloadMedia,
  extractPoster,
  getImageBlob,
  isValidHttpUrl,
  stringToColor,
} from "../../../utils/utility";
import { format } from "date-fns";
import Editor from "./VideoEditor/Editor";
import { uploader } from "../../../utils/uploader";
import { URLS } from "../../../constants";
import "./SocialOperator.css";
import "./VideoEditor/editor.css";

import "../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-image-editor/styles/bootstrap5.css";

// Registering Syncfusion license key
import { registerLicense } from "@syncfusion/ej2-base";
import Loader from "../Loader_social";
import { reviewMedia } from "./networkCallsSocial";
import {
  Close,
  FileDownload,
  GpsFixed,
  PlayCircle,
  StarBorder,
  Star,
  CloseRounded,
} from "@mui/icons-material";
import { COLORS } from "../../../utils/colors";
import {
  dataURItoBlob,
  imageDataToDataURL,
} from "../../../utils/mediaFunctions";
import AiTools from "./AiTools";
import ImageEditor from "./ImageEditor";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";

registerLicense(
  "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdEZjXn1Zc3VUQmNV"
);
const EventInteractionCard = ({
  media,
  showApproveButton,
  handleReviewMedia,
  handleReviewQuote,
  emptyCard,
  initialState,
  approved,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [mediaPreviewOpen, setMediaPreviewOpen] = useState(false);
  const [username, setUsername] = useState(media && media.userId);
  const [description, setDescription] = useState(media && media.comment);
  const [showOnQuotesChecked, setShowOnQuotesChecked] = useState(
    media && media.showOnQuotesFeed
  );
  const [editorTimings, setEditorTimings] = useState([]);
  const imageEditorRef = useRef(null);
  const videoMetaData = useRef({ width: 0, height: 0 });
  const imageMetaData = useRef({ width: 0, height: 0 });
  let updatedVideo = null;

  const [updatedImage, setUpdatedImage] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);

  const submitGeneratedImage = async (sourceMediaId) => {
    setIsLoading(true);
    const generatedImageBlob = await getImageBlob(generatedImage.url);
    setIsLoading(false);
    const payload = {
      generatedFromMediaId: sourceMediaId,
      width: generatedImage.width,
      height: generatedImage.height,
      orientation: "square",
      generatedImage: generatedImageBlob,
    };
    setIsLoading(true);
    await uploader({
      url: URLS.UPLOAD_GENERATED_MEDIA(),
      body: payload,
    });
    setIsLoading(false);
  };
  const approveButtonClicked = async () => {
    setIsLoading(true);
    try {
      let approvalId = media._id;
      if (updatedVideo || updatedImage) {
        //reject to remove old media item
        await handleReviewMedia(
          initialState.loggedInUserObj,
          approvalId,
          false,
          username,
          description,
          showOnQuotesChecked
        );

        //upload the updated/trimmed video file
        const payload = {
          eventId: initialState.event_id,
          userId: username,
          comment: description,
          userLocation: JSON.stringify(media.userLocation),
        };
        if (media.videoPosterUrl) {
          payload.videoPosterUrl = media.videoPosterUrl;
        }
        payload.mediaFile = updatedVideo
          ? updatedVideo.blob
          : updatedImage.blob;
        const uploadResponse = await uploader({
          url: URLS.UPLOAD_MEDIA(),
          body: payload,
        });
        //update the uploaded id to approve
        approvalId = uploadResponse.json.sharedMedia._id;

        await reviewMedia(
          initialState.loggedInUserObj,
          approvalId,
          true,
          username,
          description,
          showOnQuotesChecked,
          getMetaData(media?.mimeType.includes("video"))
        );
        if (generatedImage?.url && activeTab === 1) {
          await submitGeneratedImage(approvalId);
        }
      } else {
        if (media?.isQuoteOnly) {
          handleReviewQuote({
            loggedInUserObj: initialState.loggedInUserObj,
            isReviewed: true,
            ...media,
            comment: description,
            userId: username,
          });
        } else {
          // approve the media item with updated id
          const metadata = getMetaData(media?.mimeType.includes("video"));
          await handleReviewMedia(
            initialState.loggedInUserObj,
            approvalId,
            true,
            username,
            description,
            showOnQuotesChecked,
            metadata
          );
          if (generatedImage?.url && activeTab === 1) {
            await submitGeneratedImage(media._id);
          }
        }
      }

      setMediaPreviewOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLocationAddress();
  }, []);

  const [address, setAddress] = useState("0, 0");
  const getLocationAddress = () => {
    try {
      if (media && media.userLocation && media.userLocation.name) {
        setAddress(media.userLocation.name);
      } else {
        setAddress("0, 0");
      }
    } catch (error) {
      setAddress("0, 0");
    }
  };
  const getMetaData = (isVideo) => {
    if (isVideo) {
      return videoMetaData.current;
    } else if (imageMetaData.current.width && imageMetaData.current.height) {
      return imageMetaData.current;
    } else {
      if (imageEditorRef.current) {
        const imageData = imageEditorRef.current.getImageData();
        if (imageData && imageData.width && imageData.height) {
          imageMetaData.current = {
            width: imageData.width,
            height: imageData.height,
          };
        }
      }
      return imageMetaData.current;
    }
  };

  const renderEmptyCard = () => {
    return (
      <Card
        className="soCard soEventInteractionCard"
        style={{ borderWidth: "0px !important" }}
      >
        <div></div>
      </Card>
    );
  };

  const updatedVideoBlob = async (newFile) => {
    updatedVideo = newFile;
    if (newFile?.blob) {
      const videoPosterUrl = await extractPoster(undefined, newFile.blob);
      media.videoPosterUrl = videoPosterUrl;
    }
  };

  const updateVideoDimensions = (videoWidth, videoHeight) => {
    videoMetaData.current.width = videoWidth;
    videoMetaData.current.height = videoHeight;
  };

  const onVideoDataLoaded = async (fileBlob) => {
    if (
      fileBlob &&
      media.videoPosterUrl !== "" &&
      typeof media.videoPosterUrl !== "string"
    ) {
      const videoPosterUrl = await extractPoster(undefined, fileBlob);
      media.videoPosterUrl = videoPosterUrl;
    }
  };

  const beforeUnload = async () => {
    if (!updatedVideo) {
      if (media?.videoPosterUrl && isValidHttpUrl(media.videoPosterUrl)) {
        // Do nothing
      } else {
        if (media?.videoPosterUrl?.size) {
          const uploadResponse = await uploader({
            url: URLS.UPLOAD_VIDEO_POSTER(),
            body: {
              sharedMediaId: media._id,
              videoPosterUrl: media.videoPosterUrl,
            },
          });
          if (uploadResponse && uploadResponse?.json?.videoPosterUrl) {
            const newPosterUrl = uploadResponse?.json?.videoPosterUrl;
            media.videoPosterUrl = newPosterUrl;
            const el = document.getElementById(media._id);
            const blurEl = document.getElementById("blur" + media._id);
            if (el) {
              el.poster = newPosterUrl;
              el.src = "";
            }
            if (blurEl) {
              blurEl.poster = newPosterUrl;
              blurEl.src = "";
            }
          }
        }
      }
    }
  };

  const onSaveImage = () => {
    if (imageEditorRef.current) {
      const imageData = imageEditorRef.current.getImageData();
      if (imageData && imageData.width && imageData.height) {
        imageMetaData.current = {
          width: imageData.width,
          height: imageData.height,
        };
      }
      const imageDataUrl = imageDataToDataURL(imageData);
      const blob = dataURItoBlob(imageDataUrl);
      setUpdatedImage({ blob: blob, dataUrl: imageDataUrl });
    }
  };

  const renderTextInfo = () => {
    return (
      <div className="mediaPreviewInputFields">
        <div className="nameAndDescription">
          <TextField
            size="small"
            defaultValue={username}
            margin="dense"
            id="username"
            label="Username"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            fullWidth
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            size="small"
            onInput={(e) => {}}
            defaultValue={description}
            margin="dense"
            id="description"
            label="Media Description"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            fullWidth
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            multiline={true}
            sx={{ marginLeft: 1 }}
          />
        </div>

        <div className="addressDiv">
          {address.length > 4 && (
            <div
              className="gpsAddress"
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${media.userLocation.coordinates[1]},${media.userLocation.coordinates[0]}`
                );
              }}
            >
              <GpsFixed style={{ color: COLORS.textLabel }} />
              <Typography
                style={{
                  color: COLORS.textLabel,
                  textAlign: "start",
                  fontSize: 12,
                  marginLeft: 5,
                }}
              >
                {address}
              </Typography>
            </div>
          )}
          <Typography
            style={{
              color: COLORS.textLabel,
              fontSize: 12,
              marginLeft: "16px",
              padding: "10px 0px",
            }}
          >
            {"Uploaded on: " +
              format(new Date(media?.createdAt * 1000), "dd MMM yyyy hh:mm a")}
          </Typography>
        </div>
      </div>
    );
  };

  const onGenerated = (generatedMedia) => {
    setGeneratedImage(generatedMedia);
  };

  const renderImageReviewer = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Review media tabs"
            className="review-media-tabs"
          >
            <Tab label="Review Image" className="review-media-tab" />
            <Tab label="AI Transform" className="review-media-tab" />
          </Tabs>
        </Box>
        <CustomTabPanel value={activeTab} index={0}>
          <ImageEditor
            onSaveImage={onSaveImage}
            ref={imageEditorRef}
            media={media}
            showApproveButton={showApproveButton}
          />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={1}>
          <AiTools
            media={media}
            editedImage={updatedImage?.dataUrl}
            onGenerated={onGenerated}
            eventDetails={initialState}
          />
        </CustomTabPanel>
      </Box>
    );
  };

  const renderVideoEditor = () => {
    return (
      <Editor
        videoUrl={media?.mediaFileUrl}
        showControls={showApproveButton}
        timings={editorTimings}
        setTimings={setEditorTimings}
        hasVideoPoster={media?.videoPosterUrl ? true : false}
        updatedVideoBlob={updatedVideoBlob}
        updateVideoDimensions={updateVideoDimensions}
        onVideoDataLoaded={onVideoDataLoaded}
        beforeUnload={beforeUnload}
      />
    );
  };

  return emptyCard ? (
    renderEmptyCard()
  ) : (
    <Card
      className={`soCard soEventInteractionCard${
        media?.isStarred ? " starredCard" : ""
      } ${media?.isQuoteOnly ? "quoteOnlyCardOuter" : ""}`}
      style={
        media.generatedFromMediaId
          ? { border: `2px ridge ${stringToColor(media.generatedFromMediaId)}` }
          : media.hasGeneratedMediaId
          ? { border: `2px ridge ${stringToColor(media._id)}` }
          : {}
      }
    >
      <Dialog
        open={mediaPreviewOpen}
        onClose={() => setMediaPreviewOpen(false)}
        classes={{
          paper: "soDialogPaper",
        }}
      >
        <IconButton
          className="soDialogClose"
          onClick={() => setMediaPreviewOpen(false)}
        >
          <CloseRounded />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", paddingBottom: 0 }}
        >
          {media?.isQuoteOnly ? null : (
            <div className="soMediaPreview">
              {media?.mimeType?.includes("video") ? (
                renderVideoEditor()
              ) : showApproveButton ? (
                renderImageReviewer()
              ) : (
                <img
                  alt="media"
                  src={media?.mediaFileUrl}
                  className="approvedImage"
                />
              )}
            </div>
          )}
          {activeTab !== 1 && renderTextInfo()}

          {isLoading && (
            <div className="soLoaderStyle">
              <Loader />
            </div>
          )}
        </DialogContent>
        <DialogActions className="soDialogActions">
          <Button
            variant="outlined"
            onClick={() => {
              media?.isQuoteOnly
                ? handleReviewQuote({
                    loggedInUserObj: initialState.loggedInUserObj,
                    ...media,
                    comment: description,
                    userId: username,
                  })
                : handleReviewMedia(
                    initialState.loggedInUserObj,
                    media._id,
                    false,
                    username,
                    description,
                    showOnQuotesChecked
                  );
              setMediaPreviewOpen(false);
            }}
          >
            {showApproveButton ? "Reject" : "Remove"}
          </Button>
          <div
            className="soShowOnQuotesCheckbox"
            onClick={() => {
              setShowOnQuotesChecked(!showOnQuotesChecked);
            }}
          >
            <Checkbox
              checked={showOnQuotesChecked}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
              }}
            />
            <Typography color={"white"}>Approve Quote</Typography>
          </div>
          <Button
            style={{
              color: "white",
            }}
            variant="contained"
            onClick={async () => {
              await approveButtonClicked();
            }}
          >
            {showApproveButton
              ? generatedImage?.url && activeTab === 1
                ? "Approve with Generated Image"
                : "Approve"
              : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ height: "100%" }}>
        {media?.mimeType?.includes("video") ||
        media?.mimeType?.includes("text") ? (
          <div
            className="soImageMedia skeleton"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            <video
              className="soVideoMedia"
              id={"blur" + media._id}
              src={media.videoPosterUrl ? "" : media?.mediaFileUrl}
              playsInline
              loop
              controls={false}
              muted
              style={{
                objectFit: "fill",
                filter: "blur(10px)",
              }}
              disableRemotePlayback={true}
              poster={media.videoPosterUrl}
            />
            <video
              className="soVideoMedia"
              id={media._id}
              src={media.videoPosterUrl ? "" : media?.mediaFileUrl}
              playsInline
              loop
              controls={false}
              muted
              style={{
                zIndex: 11,
              }}
              disableRemotePlayback={true}
              poster={media.videoPosterUrl}
            />
            <PlayCircle
              style={{
                position: "absolute",
                alignSelf: "center",
                color: "lightgray",
                zIndex: 12,
                top: "calc(50% - 25px)",
                left: "calc(50% - 25px)",
                width: "50px",
                height: "50px",
              }}
            />
          </div>
        ) : media?.mimeType?.includes("image") ? (
          <div
            className="soImageMedia skeleton"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                objectFit: "fill",
                filter: "blur(10px)",
              }}
            />
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                position: "absolute",
                left: 0,
                objectFit: "contain",
              }}
            />
          </div>
        ) : media.isQuoteOnly ? (
          <div
            className="soImageMedia quoteOnlyCard"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            {media.comment}
          </div>
        ) : (
          <div
            className="soImageMedia skeleton"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                objectFit: "fill",
                filter: "blur(10px)",
              }}
            />
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                position: "absolute",
                left: 0,
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </div>

      <IconButton
        className="soApproveButton"
        onClick={() =>
          media?.isQuoteOnly
            ? handleReviewQuote({
                loggedInUserObj: initialState.loggedInUserObj,
                ...media,
                comment: description,
                userId: username,
                isReviewed: false,
              })
            : handleReviewMedia(
                initialState.loggedInUserObj,
                media._id,
                false,
                username,
                description,
                showOnQuotesChecked
              )
        }
        size="medium"
      >
        <Close />
      </IconButton>
      {approved && (
        <IconButton
          className="star"
          title="Starred media will not be purged."
          onClick={(e) => {
            e.stopPropagation();
            media?.isQuoteOnly
              ? handleReviewQuote({
                  loggedInUserObj: initialState.loggedInUserObj,
                  ...media,
                  comment: description,
                  userId: username,
                  isStarred: media?.isStarred ? false : true,
                })
              : handleReviewMedia(
                  initialState.loggedInUserObj,
                  media._id,
                  approved,
                  username,
                  description,
                  showOnQuotesChecked,
                  undefined,
                  media?.isStarred ? false : true
                );
          }}
          size="medium"
        >
          {media.isStarred ? (
            <Star sx={{ color: "#FFD700" }} />
          ) : (
            <StarBorder />
          )}
        </IconButton>
      )}
      {!media?.isQuoteOnly && (
        <IconButton
          className="soDownloadButton"
          onClick={() => downloadMedia(media?.mediaFileUrl, media._id)}
          size="medium"
        >
          <FileDownload />
        </IconButton>
      )}
    </Card>
  );
};

export default connect((state) => ({ initialState: state.initialState }))(
  EventInteractionCard
);
function CustomTabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        height: "calc(100% - 49px)",
        position: "relative",
        overflowY: "auto",
      }}
    >
      {value === index && children}
    </div>
  );
}
