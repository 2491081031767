import { useState, useRef, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import Loader from "../../../components/Loader/Loader";
import {
  getItemFromSessionStorage,
  mergeAndDeduplicate,
} from "../../../utils/utility";
import { Settings } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SocialOutputSettings from "../Settings";
import { URLS } from "../../../constants";
import {
  DEFAULT_SETTINGS_SOCIAL_GRID,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../../utils/defaultSettings";
import "./GridLayout.css";
import "../fixedMasonry/FixedMasonry.css";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";

import GeneratedMediaFlippingCard from "../fixedMasonry/GeneratedMediaFlippingCard";
import SingleGeneratedMediaFlippingCard from "../fixedMasonry/SingleGeneratedMediaFlippingCard";

const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.gridLayout;
const POLLING_INTERVAL = 6000;
var TOTAL_PAGES = 1;

const GridLayoutAutoResized = (props) => {
  const getInitialSettings = () => {
    if (props.savedSettings) {
      return props.savedSettings;
    } else {
      return getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        : DEFAULT_SETTINGS_SOCIAL_GRID;
    }
  };
  const [settings, setSettings] = useState(getInitialSettings);

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(-1);

  const [firstLoadArray, setFirstLoadArray] = useState([]);
  const [NumberOfCards, setNumberOfCard] = useState(
    settings.numberOfColumns * settings.numberOfRows
  );
  const [currentFlipIndex, setCurrentFlipIndex] = useState(-1);
  const [flipId, setFlipId] = useState(
    firstLoadArray.length
      ? firstLoadArray[0][0]._id + firstLoadArray[0][1]._id
      : ""
  );
  const [dialogOpen, setDialogOpen] = useState(null);
  const inventory = useRef([]);

  useEffect(() => {
    setNumberOfCard(settings.numberOfColumns * settings.numberOfRows);
  }, [settings.numberOfColumns, settings.numberOfRows]);

  useEffect(() => {
    if (NumberOfCards) {
      const init = async () => {
        setLoading(true);
        const mediaFiles = await fetchContent(0);
        setLoading(false);
        if (mediaFiles.length) {
          const { cards, leftFiles } = getFilledCardsArray(mediaFiles);
          setFirstLoadArray(cards);
          inventory.current = leftFiles;
        } else {
          setFirstLoadArray(getPlaceholderCardsArray(NumberOfCards));
        }
      };
      init();
    }
  }, [NumberOfCards]);

  useEffect(() => {
    if (page !== -1) {
      refillinventory(page);
    }
  }, [page]);

  const refillinventory = async (p) => {
    const mediaFiles = await fetchContent(p);
    const uniqueMediaList = getUniqueMediaList(mediaFiles);
    if (uniqueMediaList.length) {
      inventory.current = mergeAndDeduplicate(
        inventory.current,
        uniqueMediaList
      );
    }
  };

  const getPlaceholderCardsArray = (cardsCount) => {
    const placeholders = [];
    for (let i = 0; i < cardsCount; i++) {
      placeholders.push([
        { _id: `placeholder-${i}`, mimeType: "placeholder" },
        { _id: `placeholder-${i}1`, mimeType: "placeholder" },
      ]);
    }
    return placeholders;
  };

  const getFilledCardsArray = (mediaFiles = []) => {
    const arr = [];
    const availableFiles = [...mediaFiles];
    if (availableFiles.length) {
      for (let i = 0; i < NumberOfCards; i++) {
        let indexToRemoveFrom = -1;
        const card = [
          { _id: `placeholder-${0}-${i}`, mimeType: "placeholder" },
          { _id: `placeholder-2-${1}-${i}`, mimeType: "placeholder" },
        ];
        let originalMedia = availableFiles.find((m, i) => {
          if (m?.hasGeneratedMediaId) {
            indexToRemoveFrom = i;
            return true;
          }
          return false;
        });
        if (originalMedia && i !== -1) {
          card[0] = originalMedia;
          let indexToremoveGenerated = -1;
          availableFiles.splice(indexToRemoveFrom, 1);
          let generatedMedia = availableFiles.find((m, i) => {
            indexToremoveGenerated = i;
            if (m?.generatedFromMediaId === originalMedia._id) {
              indexToremoveGenerated = i;
              return true;
            }
            return false;
          });
          if (generatedMedia && indexToremoveGenerated !== -1) {
            card[1] = generatedMedia;
            availableFiles.splice(indexToremoveGenerated, 1);
          }
        }
        arr.push(card);
      }
    }

    return { cards: arr, leftFiles: availableFiles };
  };

  const fetchContent = async (pageNumber) => {
    try {
      const rawResponse = await uvenuFetcher({
        url: URLS.GET_SOCIAL_SCROLL(
          props.eventId,
          pageNumber,
          NumberOfCards * 4,
          undefined,
          undefined,
          "desc",
          undefined,
          undefined,
          true
        ),
        method: "GET",
      });
      const response = rawResponse.json;
      const mediaFiles = response?.mediaFiles || [];
      const totalMediaCount = response.totalCount;
      const perPage = NumberOfCards * 4;
      TOTAL_PAGES = Math.ceil(totalMediaCount / perPage);
      return mediaFiles;
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };
  const getRootContainerStyles = () => {
    return {
      height: props.height,
      width: props.width,
      backgroundColor: settings.backgroundColor,
      backgroundImage: settings.backgroundImage
        ? `url(${settings.backgroundImage})`
        : "none",
    };
  };
  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };
  useEffect(() => {
    if (firstLoadArray.length) {
      const currentCard = firstLoadArray[currentFlipIndex];
      if (currentCard) {
        const id1 = currentCard[0]?._id;
        const id2 = currentCard[1]._id;
        if (!id1?.includes("placeholder") && !id2?.includes("placeholder")) {
          setFlipId(
            firstLoadArray[currentFlipIndex][0]._id +
              firstLoadArray[currentFlipIndex][1]._id
          );
        }
      }
    }
  }, [currentFlipIndex, firstLoadArray.length]);

  useEffect(() => {
    if (!firstLoadArray.length) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentFlipIndex((pre) => {
        if (pre < NumberOfCards - 1) {
          return pre + 1;
        } else if (pre === NumberOfCards - 1) {
          return 0;
        } else {
          return pre;
        }
      });
    }, settings.flipDelay * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [firstLoadArray.length, settings.flipDelay, NumberOfCards]);
  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog open={dialogOpen || false} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="dialog-content">
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={settings}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  useEffect(() => {
    let intervalId;
    if (firstLoadArray.length) {
      intervalId = setInterval(() => {
        if (TOTAL_PAGES > 1) {
          setPage((prePage) => {
            if (prePage < TOTAL_PAGES - 1) {
              return prePage + 1;
            } else {
              return 0;
            }
          });
        } else {
          refillinventory(0);
        }
      }, POLLING_INTERVAL);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [firstLoadArray.length]);

  const onRequestNewMeida = (cb, getOriginal, requestedMediaId) => {
    if (inventory.current.length) {
      const invetoryCopy = [...inventory.current];
      if (getOriginal) {
        let indexToRemoveFrom = -1;
        const newOriginalMedia = invetoryCopy.find((m, i) => {
          if (m?.hasGeneratedMediaId) {
            indexToRemoveFrom = i;
            return true;
          }
          return false;
        });
        if (newOriginalMedia && indexToRemoveFrom !== -1) {
          cb(newOriginalMedia);
          invetoryCopy.splice(indexToRemoveFrom, 1);
          inventory.current = invetoryCopy;
        }
      } else if (requestedMediaId) {
        let indexToRemoveFrom = -1;
        const newGeneratedMedia = invetoryCopy.find((m, i) => {
          if (m._id === requestedMediaId) {
            indexToRemoveFrom = i;
            return true;
          }
          return false;
        });
        if (newGeneratedMedia && indexToRemoveFrom !== -1) {
          cb(newGeneratedMedia);
          invetoryCopy.splice(indexToRemoveFrom, 1);
          inventory.current = invetoryCopy;
        }
      }
    }
  };
  const getUniqueMediaList = (sourceArray) => {
    const uniqueMediaList = sourceArray.filter((m) => {
      if (!document.getElementById(m._id)) {
        return true;
      }
      return false;
    });
    return uniqueMediaList;
  };
  const getLayoutStyles = () => {
    if (settings.selectedGridLayoutOption === 1) {
      // Row Layout
      return {
        width: `calc(100% - ${settings.gap}px)`,
        gridTemplateColumns: `repeat(${settings.numberOfColumns}, 1fr)`,
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedGridLayoutOption === 2) {
      // Column Layout
      return {
        height: `calc(100% - ${settings.gap * 2}px)`,
        width: `${
          (props.height / settings.numberOfRows - settings.gap * 2) *
            (9 / 16) *
            settings.numberOfColumns +
          settings.gap * 2 * (settings.numberOfColumns - 1)
        }px`,
        gridTemplateRows: `repeat(${settings.numberOfRows}, 1fr)`,
        gridTemplateColumns: `repeat(${settings.numberOfColumns}, auto)`,
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    } else if (settings.selectedGridLayoutOption === 169) {
      // 16 : 9
      return {
        aspectRatio: 2.4,
        width: props.height * 2.4 - settings.gap * 2 * 2.4,
        gridTemplateColumns: `repeat(${settings.numberOfColumns}, 1fr)`,
        columnGap: `${settings.gap}px`,
        rowGap: `${settings.gap}px`,
        padding: `${settings.gap}px`,
      };
    }
  };

  useEffect(() => {
    props.onSettingsChange(settings);
  }, [settings]);
  useEffect(() => {
    if (props.savedSettings) {
      setSettings(props.savedSettings);
    }
  }, [props.savedSettings]);

  return (
    <div className="root-container-grid" style={getRootContainerStyles()}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {renderSettings()}
          <div style={getLayoutStyles()} className="uv-social-grid">
            {NumberOfCards === 1
              ? firstLoadArray.length && (
                  <SingleGeneratedMediaFlippingCard
                    settings={settings}
                    m1={firstLoadArray[0][0]}
                    m2={firstLoadArray[0][1]}
                    key={NumberOfCards}
                    onRequestNewMeida={onRequestNewMeida}
                    className={`card-grid-${settings.cardOrientation}${
                      settings.selectedGridLayoutOption &&
                      settings.selectedGridLayoutOption === 2
                        ? " social-grid-vertical-card"
                        : ""
                    }`}
                  />
                )
              : firstLoadArray.map((s, i) => {
                  return i < NumberOfCards && s && s[0] && s[1] ? (
                    <GeneratedMediaFlippingCard
                      settings={settings}
                      m1={s[0]}
                      m2={s[1]}
                      key={s[0]._id + s[1]._id}
                      flipId={flipId}
                      onRequestNewMeida={onRequestNewMeida}
                      className={`card-grid-${settings.cardOrientation}${
                        settings.selectedGridLayoutOption &&
                        settings.selectedGridLayoutOption === 2
                          ? " social-grid-vertical-card"
                          : ""
                      }`}
                    />
                  ) : null;
                })}
          </div>
        </>
      )}
    </div>
  );
};
const AiMediaGrid = (props) => {
  return (
    <>
      <div style={{ position: "fixed", inset: 0 }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <GridLayoutAutoResized
                width={width}
                height={height}
                {...props}
              ></GridLayoutAutoResized>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};
export default AiMediaGrid;
